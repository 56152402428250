<template>
  <div class="row">
    <div class="col">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"/>
      <transition name="slide">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-auto">
                <h5 class="mt-2 mb-0">Registro de Materias</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <label for="alumnoCarrera"><strong>Carrera</strong></label>
                <select id="alumnoCarrera" required="required"
                        v-model="carrera_id" class="form-control"
                        @change="actualizaMaterias()">
                  <option value="0">:: SELECCIONAR ::</option>
                  <option v-for="option in carreras" :value="option.value">{{ option.label }}
                  </option>
                </select>
              </div>
              <div class="col-3">
                <strong>
                  <CSelect
                      :options="planes"
                      :plain="true"
                      :value.sync="plan_id"
                      label="Plan de Estudios"
                      required="required"
                      @change="actualizaMaterias()"
                  >
                  </CSelect>
                </strong>
              </div>
              <div class="col-2">
                <button class="btn btn-primary w-100" @click="nuevaMateria">Nueva<br/>Materia</button>
              </div>
              <div class="col-2">
                <button class="btn btn-outline-danger w-100" @click="mallacurricularpdf">Exportar Malla curricular
                </button>
              </div>
            </div>


            <template v-if="mostrarMaterias">
              <div class="row">
                <div v-for="(semestre, index) in materias" class="col-12">
                  <div class="card border-info">
                    <div class="card-header text-center"><h4>Semestre {{ index }}</h4></div>
                    <div class="card-body p-0">
                      <table class="table table-sm table-bordered table-hover m-0  align-middle">
                        <thead>
                        <tr>
                          <th class="text-center align-middle" width="8%"><span>Sigla</span></th>
                          <th class="text-center align-middle"><span>Materia</span></th>
                          <!--                          <th class="text-center align-middle" width="7%"><span>Electiva</span></th>-->
                          <!--                          <th class="text-center align-middle" width="7%"><span>Ofertada</span></th>-->
                          <!--                          <th class="text-center align-middle" width="7%"><span>Semestral / anual</span></th>-->
                          <th class="text-center align-middle" width="7%"><span>Horas Teóricas</span></th>
                          <th class="text-center align-middle" width="7%"><span>Horas Prácticas</span></th>
                          <th class="text-center align-middle" width="7%"><span>Horas Totales</span></th>
                          <th class="text-center align-middle" width="7%">Créditos</th>
                          <th class="text-center align-middle" width="10%">Prerrequisitos</th>
                          <th class="text-center align-middle" width="25%"></th>
                        </tr>
                        </thead>
                        <tbody class="table-group-divider">
                        <tr v-for="(materia, index2) in semestre">
                          <td class="text-center align-middle">{{ materia.sigla }}</td>
                          <td class="text-left   align-middle">{{ materia.materia }}</td>
                          <!--                          <td class="text-center align-middle">{{ materia.electiva }}</td>-->
                          <!--                          <td class="text-center align-middle">{{ materia.ofertada }}</td>-->
                          <!--                          <td class="text-center align-middle">{{ materia.semestral_anual }}</td>-->
                          <td class="text-center align-middle">{{ materia.horas_teoricas }}</td>
                          <td class="text-center align-middle">{{ materia.horas_practicas }}</td>
                          <td class="text-center align-middle">{{ materia.horas_totales }}</td>
                          <td class="text-center align-middle">{{ materia.creditos }}</td>
                          <td class="text-center align-middle">{{ materia.prerrequisitos }}</td>
                          <td class="text-center align-middle">
                            <button type="button" class="btn btn-success me-3 mr-3" @click="editarMateria(materia)">
                              Editar
                            </button>
                            <button type="button" class="btn btn-warning" @click="prerrequisitoMateria(materia)">
                              Prerrequisitos
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </transition>
    </div>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalMateriaEditar"
        size="xl"
    >
      <template #body-wrapper>
        <form class="was-validated">
          <table class="table table-sm table-bordered">
            <tr>
              <th class="text-center align-middle" width="9%"><span>Semestre</span></th>
              <th class="text-center align-middle" width="12%"><span>Sigla</span></th>
              <th class="text-center align-middle"><span>Materia</span></th>
              <!--                          <th class="text-center align-middle" width="7%"><span>Electiva</span></th>-->
              <!--                          <th class="text-center align-middle" width="7%"><span>Ofertada</span></th>-->
              <!--                          <th class="text-center align-middle" width="7%"><span>Semestral / anual</span></th>-->
              <th class="text-center align-middle" width="9%"><span>Horas<br/>Teóricas</span></th>
              <th class="text-center align-middle" width="9%"><span>Horas<br/>Prácticas</span></th>
              <th class="text-center align-middle" width="9%"><span>Horas<br/>Totales</span></th>
              <th class="text-center align-middle" width="9%">Créditos</th>
            </tr>
            <tr>
              <td class="text-center align-middle position-relative">
                <input type="text" class="form-control text-center" required="required"
                       v-model="materiaSeleccionada.semestre">
                <div class="invalid-tooltip">Dato Requerido</div>
              </td>
              <td class="text-center align-middle position-relative">
                <input type="text" class="form-control text-center" required="required"
                       v-model="materiaSeleccionada.sigla"
                       @change="materiaSeleccionada.sigla=materiaSeleccionada.sigla.toUpperCase()">
                <div class="invalid-tooltip">Dato Requerido</div>
              </td>
              <td class="text-left   align-middle position-relative">
                <textarea class="form-control" required="required" rows="2"
                          v-model="materiaSeleccionada.materia"
                          @change="materiaSeleccionada.materia=materiaSeleccionada.materia.toUpperCase()"></textarea>
                <div class="invalid-tooltip">Dato Requerido</div>
              </td>
              <!--                          <td class="text-center align-middle position-relative">{{ materiaSeleccionada.electiva }}</td>-->
              <!--                          <td class="text-center align-middle position-relative">{{ materiaSeleccionada.ofertada }}</td>-->
              <!--                          <td class="text-center align-middle position-relative">{{ materiaSeleccionada.semestral_anual }}</td>-->
              <td class="text-center align-middle position-relative">
                <input type="text" class="form-control text-center" required="required"
                       v-model="materiaSeleccionada.horas_teoricas">
                <div class="invalid-tooltip">Dato Requerido</div>
              </td>
              <td class="text-center align-middle position-relative">
                <input type="text" class="form-control text-center" required="required"
                       v-model="materiaSeleccionada.horas_practicas">
                <div class="invalid-tooltip">Dato Requerido</div>
              </td>
              <td class="text-center align-middle position-relative">
                <input type="text" class="form-control text-center" required="required"
                       v-model="materiaSeleccionada.horas_totales">
                <div class="invalid-tooltip">Dato Requerido</div>
              </td>
              <td class="text-center align-middle position-relative">
                <input type="text" class="form-control text-center" required="required"
                       v-model="materiaSeleccionada.creditos">
                <div class="invalid-tooltip">Dato Requerido</div>
              </td>
            </tr>
          </table>
        </form>
      </template>
      <template #header>
        <h4>{{ materiaSeleccionada.sigla }} - {{ materiaSeleccionada.materia }}</h4>
        <CButtonClose @click="modalMateriaEditar = false;"/>
      </template>
      <template #footer-wrapper>
        <div class="row">
          <div class="col-12 text-center p-2">
            <button type="button" class="btn btn-success" @click="materiagrabar">Grabar</button>
            <button type="button" class="btn btn-danger mx-4" @click="materiaeliminar">Eliminar</button>
            <button type="button" class="btn btn-outline-dark" @click="modalMateriaEditar = false;">Cancelar (Cerrar sin
              guardar cambios)
            </button>
          </div>
        </div>
      </template>
    </CModal>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalMateriaPrerrequisito"
        size="xl"
    >
        <template v-if="modalMateriaPrerrequisito">
          <div class="row">
            <div v-for="(semestre, index) in materias" class="col-6 px-2">
              <div class="card border-info">
                <div class="card-header text-center"><strong>Semestre {{ index }}</strong></div>
                <div class="card-body p-0">
                  <table class="table table-sm table-hover m-0">
                    <thead>
                    <tr>
                      <th width="10%" class="text-center">
                        <button type="button" class="btn btn-outline-danger btn-sm"
                        @click="todoElSemestre(semestre, materiaSeleccionada.id)">Todo</button>
                      </th>
                      <th width="13%" class="text-center"><span>Sigla</span></th>
                      <th width="77%"><span>Materia</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(materia, index2) in semestre">
                      <td class="text-center">
                        <input :value="materia.id" :id="'materiaSeleccionada'+materia.id" style="height: 19px"
                               class="form-control" type="checkbox" :checked="esPrerrequisitoMateria(materia.sigla)"
                               @change="prerrequisitograbar($event,materiaSeleccionada.id,materia.id)"
                        />
                      </td>
                      <td class="text-center px-0">{{ materia.sigla }}</td>
                      <td class="pr-0 pe-0">{{ materia.materia }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </template>

      <template #header>
        <h4>Prerrequisitos para: <span class="text-primary">{{ materiaSeleccionada.sigla }} - {{ materiaSeleccionada.materia }}</span></h4>
        <CButtonClose @click="modalMateriaPrerrequisito = false;"/>
      </template>
      <template #footer-wrapper>
        <div class="row">
          <div class="col-12 text-center p-2">
            <button type="button" class="btn btn-outline-dark" @click="modalMateriaPrerrequisito = false;">
              Cerrar ventana
            </button>
          </div>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import Alerts from "@/views/notifications/Alerts";
import Confirm from "@/views/notifications/Confirm";
import Toast from "@/views/notifications/Toast";
import ModalPDF from "@/views/notifications/ModalPDF";

var sourceLst;
var address;
var modalPdf;

export default {
  name: 'Materias',
  components: {
    Toast,
    Confirm,
    ModalPDF,
    Alerts,
  },
  data: () => {
    return {
      items: [],
      mostrarMaterias: false,
      modalMateriaEditar: false,
      modalMateriaPrerrequisito: false,
      materiaPorDefecto: ":: SELECCIONAR ::",
      carrera_id: 0,
      carreras: [],
      plan_id: 0,
      planes: [],
      materias: [],
      materiaSeleccionada: [],
      semestres: [],
    }
  },
  beforeCreate: function () {
    address = this.$apiAdress;
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf('admin') >= 0) {
          return true
        }
        return roles != null && roles.indexOf(roleUser) >= 0;
      }
    },

    nuevaMateria: function () {
      modalPdf.materiaSeleccionada = {
        creditos: 0,
        electiva: "N",
        horas_practicas: 0,
        horas_teoricas: 0,
        horas_totales: 0,
        id: 0,
        materia: "",
        ofertada: "S",
        prerrequisitos: "NINGUNO",
        semestral_anual: "S",
        semestre: 1,
        sigla: ""
      }
      modalPdf.modalMateriaEditar = true
    },
    eliminar: function (docenteid, id) {
      modalPdf.$refs.confirm('', 'Eliminar este registro', function (docenteid, id) {
        this.$refs.gridSystem.updatebounddata();
      })
    },
    editarMateria: function (materia) {
      modalPdf.materiaSeleccionada = materia
      modalPdf.modalMateriaEditar = true
    },
    prerrequisitoMateria: function (materia) {
      modalPdf.materiaSeleccionada = materia
      modalPdf.modalMateriaPrerrequisito = true
    },
    esPrerrequisitoMateria: function (sigla) {
      if(modalPdf.materiaSeleccionada.prerrequisitos!=="NINGUNO" && modalPdf.materiaSeleccionada.prerrequisitos!==undefined){
        let prerrequisitosMateriaSeleccionada = modalPdf.materiaSeleccionada.prerrequisitos.split(",")
        return prerrequisitosMateriaSeleccionada.includes(sigla)
      }
      return false;
    },
    todoElSemestre(semestre,materia_id){
      for (var key in semestre) {
        var materia = semestre[key];
        document.getElementById("materiaSeleccionada"+materia.id).checked = true;
        axios.post(
            address + '/api/materias/' + localStorage.sedeSeleccionada + '/prerrequisitograbar?token=' + localStorage.getItem("api_token"),
            {'materia_id':materia_id, 'prerrequisito_materia_id':materia.id}
        )
            .then((response) => {
              modalPdf.$refs.mensajeToast.makeToast('', response.data.message, 'success')
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
            })
      }
    },
    prerrequisitograbar(event, materia_id, prerrequisito_materia_id) {
      if (event.target.checked) {
        axios.post(
            address + '/api/materias/' + localStorage.sedeSeleccionada + '/prerrequisitograbar?token=' + localStorage.getItem("api_token"),
            {'materia_id':materia_id, 'prerrequisito_materia_id':prerrequisito_materia_id}
        )
            .then((response) => {
              modalPdf.$refs.mensajeToast.makeToast('', response.data.message, 'success')
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
            })
      }else{
        axios.post(
            address + '/api/materias/' + localStorage.sedeSeleccionada + '/prerrequisitoeliminar?token=' + localStorage.getItem("api_token"),
            {'materia_id':materia_id, 'prerrequisito_materia_id':prerrequisito_materia_id}
        )
            .then((response) => {
              modalPdf.$refs.mensajeToast.makeToast('', response.data.message, 'warning')
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
            })
      }
    },
    materiagrabar: function () {
      modalPdf.modalMateriaEditar = false;
      this.$refs.confirm.confirm('¿Esta segur@ de grabar los datos de la materia?', '', function () {
        modalPdf.$refs.alert.show('Grabando...');
        modalPdf.materiaSeleccionada.carrera_id = modalPdf.carrera_id
        modalPdf.materiaSeleccionada.plan_id = modalPdf.plan_id
        axios.post(
            address + '/api/materias/' + localStorage.sedeSeleccionada + '/materiagrabar?token=' + localStorage.getItem("api_token"),
            modalPdf.materiaSeleccionada
        )
            .then((response) => {
              modalPdf.actualizaMaterias();
              modalPdf.modalMateriaEditar = false;
            })
            .catch(function (error) {
              modalPdf.modalMateriaEditar = true;
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
            })
      }, function () {
        modalPdf.modalMateriaEditar = true;
      });
    },
    materiaeliminar: function (event) {
      modalPdf.modalMateriaEditar = false;
      this.$refs.confirm.confirm('¿Esta segur@ de eliminar esta materia?', '', function () {
        modalPdf.$refs.alert.show('Eliminando...');
        axios.get(
            address + '/api/materias/' + localStorage.sedeSeleccionada + '/' + modalPdf.materiaSeleccionada.id + '/materiaeliminar?token=' + localStorage.getItem("api_token")
        )
            .then((response) => {
              modalPdf.actualizaMaterias();
              modalPdf.modalMateriaEditar = false;
            })
            .catch(function (error) {
              modalPdf.modalMateriaEditar = true;
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
            })
      }, function () {
        modalPdf.modalMateriaEditar = true;
      });
    },
    actualizaCarreras: function (event) {
      modalPdf.carrera_id = 0;
      modalPdf.carreras = [];
      axios.get(this.$apiAdress + '/api/materias/' + localStorage.sedeSeleccionada + '/parametros?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
            modalPdf.carreras = response.data.carreras;
            if (Object.keys(response.data.planes).length > 1) {
              modalPdf.planes = valorPorDefecto.concat(response.data.planes);
              modalPdf.plan_id = "";
            } else {
              modalPdf.planes = response.data.planes;
              modalPdf.plan_id = response.data.planes[0].value
            }
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    actualizaMaterias: function (event) {
      modalPdf.materia_id = 0;
      modalPdf.materias = []
      if (modalPdf.carrera_id != '' && modalPdf.plan_id != '') {
        axios.get(modalPdf.$apiAdress + '/api/materias/' + localStorage.sedeSeleccionada + '/' + modalPdf.carrera_id + '/' + modalPdf.plan_id + '/listarmaterias?token=' + localStorage.getItem("api_token"))
            .then(function (response) {
              if (Object.keys(response.data.semestres).length > 0) {
                modalPdf.semestres = response.data.semestres;
                modalPdf.materias = response.data.materias;
                modalPdf.mostrarMaterias = true
              }
            })
            .catch(function (error) {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                if (error.response.status == 401) {
                  modalPdf.$router.push({path: '/login'});
                }
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
              modalPdf.mostrarMaterias = false
            });
      }
    },
    mallacurricularpdf: function (event) {
      modalPdf.$refs.alert.show("Descargando ...");
      axios.get(this.$apiAdress + '/api/reporte/' + modalPdf.carrera_id + '/' + modalPdf.plan_id + '/mallacurricularpdf?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
          })
          .catch(function (error) {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    }
  },
  computed: {},
  watch: {
    modalMateriaPrerrequisito (val) {
      if(!val){
        modalPdf.actualizaMaterias()
      }
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    modalPdf.actualizaCarreras();
  },
}
</script>